import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { useUserProvider } from '@wt/game/providers/user/UserProvider';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { useEffect, useState } from 'react';

export const AccountAvatar = () => {
  const { loggedIn, user } = useUserProvider();

  return (
    <>
      {loggedIn ? <AccountPicture imageUrl={user.photoURL} /> : <LoginButton />}
    </>
  );
};

const LoginButton = () => {
  const { showLoginTooltip, setShowLoginToolip } = useUserProvider();

  useEffect(() => {
    if (!showLoginTooltip) return;
    document && document.body.classList.add('tooltip');
  }, [showLoginTooltip]);

  const handleClickOk = () => {
    setShowLoginToolip(false);
    document && document.body.classList.remove('tooltip');
    return;
  };

  const handleClickDontShowAgain = () => {
    setShowLoginToolip(false);
    localStorage.setItem('hide-login-tooltip', JSON.stringify(true));
    document && document.body.classList.remove('tooltip');
    return;
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF8DD',
      boxShadow: '0px 0px 10px 0px rgba(124, 107, 11, 0.20)',
      borderRadius: '8px',
      color: '#000',
      maxWidth: 250,
      fontSize: 14,
      padding: '12px',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FFF8DD',
    },
  }));

  return (
    <HtmlTooltip
      arrow
      open={showLoginTooltip}
      title={
        <div className="flex w-full flex-col items-center justify-center gap-3 text-center">
          <p>Log in to make sure you save your stats!</p>
          <div className="flex w-full items-center justify-center gap-3">
            <Link
              href={'#'}
              onClick={handleClickOk}
              className="btn h-[35px] min-h-[35px] w-fit shrink rounded-xl border border-gray-400 bg-base-300 text-sm font-normal text-base-100 hover:bg-base-300/80"
            >
              OK
            </Link>
            <Link
              href={'#'}
              onClick={handleClickDontShowAgain}
              className="btn h-[35px] min-h-[35px] w-fit shrink rounded-xl border border-gray-400 bg-base-300 text-sm font-normal text-base-100 hover:bg-base-300/80"
            >
              Don't show again
            </Link>
          </div>
        </div>
      }
    >
      <Link href={'/login'}>
        <AccountBoxOutlinedIcon fontSize="large" className="hidden md:flex" />
        <AccountBoxOutlinedIcon fontSize="medium" className="flex md:hidden" />
      </Link>
    </HtmlTooltip>
  );
};

const AccountPicture = ({ imageUrl }: { imageUrl: string }) => {
  const [imgSrc, setImgSrc] = useState<string>(imageUrl);

  useEffect(() => {
    if (imgSrc !== imageUrl) {
      setImgSrc(imageUrl);
    }
  }, [imageUrl]);

  const handleError = () => {
    setImgSrc('fallbackIcon.svg');
  };

  return (
    <Link href={'/account'} className="flex items-center justify-center">
      <div className="relative size-[24px] overflow-hidden rounded-full md:size-[35px]">
        {imgSrc !== 'fallbackIcon.svg' && !isEmpty(imgSrc) ? (
          <img
            src={imgSrc}
            referrerPolicy="no-referrer"
            onError={handleError}
            aria-label="Account"
            className="absolute inset-0 size-full object-cover object-center"
          />
        ) : (
          <AccountBoxOutlinedIcon className="fill-current text-3xl" />
        )}
      </div>
    </Link>
  );
};
