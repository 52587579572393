import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { getSiteMode } from '@wt/utilities/siteMode';
import Link from 'next/link';
import { useEffect, useState } from 'react';

const WHENTAKEN_UPLOAD_FORM_LINK = 'https://forms.gle/oPZZdWLePuboU17N8';
const WHERETAKEN_UPLOAD_FORM_LINK = 'https://forms.gle/TEhkqmLYpiib81ZY9';

export const UploadButton = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    try {
      const storedSeenTooltip = localStorage.getItem('seen-upload-tooltip');
      const seenTooltip: boolean =
        storedSeenTooltip != null ? JSON.parse(storedSeenTooltip) : false;
      if (seenTooltip) return;

      document && document.body.classList.add('tooltip');

      setOpen(true);
      localStorage.setItem('seen-upload-tooltip', JSON.stringify(true));
    } catch (e) {
      document && document.body.classList.remove('tooltip');
      return;
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    document && document.body.classList.remove('tooltip');
    return;
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF8DD',
      boxShadow: '0px 0px 10px 0px rgba(124, 107, 11, 0.20)',
      borderRadius: '8px',
      color: '#000',
      maxWidth: 215,
      fontSize: 14,
      padding: '12px',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FFF8DD',
    },
  }));

  return (
    <HtmlTooltip
      arrow
      open={open}
      title={
        <div className="flex w-full flex-col items-center justify-center gap-3 text-center">
          <p>You can now submit your own images for our daily game!</p>
          <Link
            href={'#'}
            onClick={handleClose}
            className="btn h-[35px] min-h-[35px] w-fit shrink rounded-xl border border-gray-400 bg-base-300 text-sm font-normal text-base-100 hover:bg-base-300/80"
          >
            OK
          </Link>
        </div>
      }
    >
      <Link
        href={
          getSiteMode() === 'whentaken'
            ? WHENTAKEN_UPLOAD_FORM_LINK
            : WHERETAKEN_UPLOAD_FORM_LINK
        }
        target="_blank"
      >
        <FileUploadOutlinedIcon fontSize="large" className="hidden md:flex" />
        <FileUploadOutlinedIcon fontSize="medium" className="flex md:hidden" />
      </Link>
    </HtmlTooltip>
  );
};
